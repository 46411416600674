import React, { Component } from "react"
import "./Timeline.css"

export default class Timeline2 extends Component {
  render() {
    const { periods } = this.props
    const animationDuration = 2 // seconds
    return (
      <div className="timeline">
        <ul>
          {periods &&
            periods.map((period, i) => {
              return (
                <li className="entry">
                  <div
                    className="entry_left"
                    style={{
                      animationDuration: animationDuration + 0 + "s",
                      animationDelay: animationDuration * (i + 0.5) + "s",
                    }}
                  >
                    <p>{period.year}</p>
                  </div>

                  <div className="meter">
                    <span style={{ width: `100%` }}>
                      <span
                        className="progress"
                        style={{
                          animationDuration,
                          animationDelay: animationDuration * i + "s",
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className="dot"
                    style={{
                      animationDuration: animationDuration + 0 + "s",
                      animationDelay: animationDuration * (i + 0.4) + "s",
                    }}
                  />

                  <div
                    className="entry_right"
                    style={{
                      animationDuration: animationDuration + 0 + "s",
                      animationDelay: animationDuration * (i + 0.5) + "s",
                    }}
                  >
                    <p>{period.text}</p>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }
}
