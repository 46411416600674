import React, { Component } from "react"

import Timeline from "../components/Globals/Timeline/Timeline.js"

import SEO from "../components/seo"
import Layout from "../components/layout"

const About = () => (
  <>
    <SEO title="About" />
    <div className="background_about">
      <div className="left_container">
        <h1 className="about_title">A bit about me</h1>
      </div>
      <div className="right_container">
        <div className="about_text_container">
          <p>
            Hello! I’m Tom. I'm a Full Stack web developer in Central
            London.
          </p>

          <p>
            My first Python script was written on a North Sea oil rig to enable pressure alerts. 
          </p>

          <p>
            I enjoyed writing code so I began using web frameworks to
            build projects and learning other programming languages.
          </p>

          <p>
            I’ve since been building web apps for London's startups, primarily.
          </p>

          <p>
            Building functional and user-friendly applications is something I
            love to do.
          </p>
        </div>
      </div>

      <div className="developer-london-home-desk" />
    </div>
  </>
)

export default About
